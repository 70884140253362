import React, { useState, useEffect } from "react";
import eventImage from "../assets/images/event.png"; // Asegúrate de tener esta imagen en la ruta correcta

const Popup = () => {
  const [visible, setVisible] = useState(true);
  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setMinimized(true);
    }, 10000); // 10 segundos

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    setVisible(true);
    setMinimized(false);
  };

  return (
    <>
      {visible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative max-w-3xl w-full h-full flex flex-col items-center justify-center">
            <button
              className="absolute top-4 right-4 text-white text-3xl"
              onClick={() => setVisible(false)}
            >
              &times;
            </button>
            <img
              src={eventImage}
              alt="Event"
              className="w-full h-auto object-cover max-h-full"
            />
          </div>
        </div>
      )}
      {minimized && (
        <div
          className="fixed bottom-4 right-4 bg-white p-2 rounded-lg shadow-lg cursor-pointer z-50"
          onClick={handleClick}
        >
          <img
            src={eventImage}
            alt="Event"
            className="w-24 h-24 rounded-lg object-cover"
          />
        </div>
      )}
    </>
  );
};

export default Popup;
