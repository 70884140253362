import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="mb-4 md:mb-0">
            <h3 className="text-2xl font-bold mb-2">Luces Nuevas</h3>
            <p>
              Luces Nuevas Internacional es una organización dedicada a
              proporcionar acceso universal y sostenible a la energía.
            </p>
          </div>
          <div className="mb-4 md:mb-0">
            <h3 className="text-2xl font-bold mb-2">Enlaces Rápidos</h3>
            <ul>
              <li className="mb-2">
                <a href="/" className="hover:underline">
                  Inicio
                </a>
              </li>
              <li className="mb-2">
                <a href="/about" className="hover:underline">
                  Acerca de
                </a>
              </li>
              <li className="mb-2">
                <a href="/projects" className="hover:underline">
                  Proyectos
                </a>
              </li>
              <li className="mb-2">
                <a href="/contact" className="hover:underline">
                  Contacto
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <h3 className="text-2xl font-bold mb-2">Síguenos</h3>
            <div className="flex space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-2xl hover:text-blue-500"
                />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-2xl hover:text-blue-400"
                />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-2xl hover:text-pink-500"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <p>
            &copy; 2024 Luces Nuevas Internacional. Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
