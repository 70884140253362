import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import teamImage from "../assets/images/team1.jpg"; // Asegúrate de tener esta imagen en la ruta correcta
import aboutBanner from "../assets/images/banner2.jpg"; // Asegúrate de tener esta imagen en la ruta correcta

const About = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header
        className="relative w-full h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${aboutBanner})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <h1 className="text-3xl md:text-5xl font-bold text-white text-center">
            Acerca de Nosotros
          </h1>
        </div>
      </header>
      <main className="flex-grow p-6 md:p-10">
        <div className="max-w-7xl mx-auto">
          <section className="mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
              Historia
            </h2>
            <p className="text-base md:text-lg text-gray-700">
              Desde 2010, perseguimos un objetivo social: "Acceso Universal a la
              Energía" (AUE). Llevamos a cabo modelos de proyectos, promoviendo
              estudios socioeconómicos sobre los impactos de velas y
              encendedores en hogares sin electricidad; así como el análisis de
              la energía para cocinar en dichos hogares. Para este fin, se ha
              diseñado un modelo de diagnóstico y gestión. De forma
              complementaria proponemos acciones en el ámbito de Acceso
              Universal a las Tecnologías de la Información y Comunicación; así
              como acciones de Agricultura y Manejo Forestal Sostenible.
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Luces Nuevas Internacional se dedica a proporcionar acceso
              universal y sostenible a la energía, mejorando la calidad de vida
              de las comunidades y promoviendo el desarrollo sostenible.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
              Visión
            </h2>
            <p className="text-base md:text-lg text-gray-700">
              Desarrollar modelos de gestión conducentes al acceso universal a
              la energía (AUE) y nuevas tecnologías que puedan ayudar a
              establecer políticas públicas acertadas.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
              Valores
            </h2>
            <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
              <li>Compromiso con la sostenibilidad</li>
              <li>Innovación en soluciones energéticas</li>
              <li>Colaboración y trabajo en equipo</li>
              <li>Transparencia y responsabilidad</li>
            </ul>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
              Nuestro Equipo
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <img
                  src={teamImage}
                  alt="Nuestro Equipo"
                  className="w-full h-auto object-cover rounded-lg shadow-lg"
                />
              </div>
              <div>
                <p className="text-base md:text-lg text-gray-700">
                  Contamos con un equipo multidisciplinario de profesionales
                  comprometidos con nuestra misión y visión. Nuestro equipo
                  incluye expertos en energía renovable, ingenieros, científicos
                  y miembros de la comunidad que trabajan juntos para
                  implementar soluciones sostenibles.
                </p>
                <p className="text-base md:text-lg text-gray-700 mt-4">
                  Creemos en la importancia de la colaboración y el trabajo en
                  equipo para alcanzar nuestros objetivos y crear un impacto
                  positivo en las comunidades con las que trabajamos.
                </p>
              </div>
            </div>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
              Proyectos y Cómo Trabajamos
            </h2>
            <p className="text-base md:text-lg text-gray-700 mb-4">
              Nuestra organización trabaja en proyectos de acceso universal a la
              energía en varias regiones, con un enfoque en la gobernanza y
              planificación dinámica. Aquí algunos de nuestros enfoques y
              proyectos destacados:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold text-primary mb-2">
                  Gobernanza y Planificación Dinámica
                </h3>
                <p className="text-base text-gray-700">
                  Implementamos procesos de gobernanza que incluyen la
                  participación del estado, la sociedad civil y las
                  instituciones privadas para generar diálogos y consensos en la
                  toma de decisiones.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold text-primary mb-2">
                  Proyecto en Pasorapa
                </h3>
                <p className="text-base text-gray-700">
                  En 2014, Pasorapa se convirtió en el primer municipio en
                  Bolivia con acceso universal a la energía mediante
                  picolámparas, beneficiando a 380 familias.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold text-primary mb-2">
                  Proyecto en Tiquipaya
                </h3>
                <p className="text-base text-gray-700">
                  Tiquipaya ha implementado modelos de gestión para el acceso
                  universal a la energía, integrando a todas las partes
                  interesadas y promoviendo un ambiente de cooperación.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold text-primary mb-2">
                  Soluciones Tecnológicas
                </h3>
                <p className="text-base text-gray-700">
                  Utilizamos tecnologías eficientes y sostenibles como litio
                  solar AC y DC, microgrids y cocinas de pellets para
                  proporcionar soluciones energéticas a las comunidades.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default About;
