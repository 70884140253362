import React from "react";
import Slider from "react-slick";
import brand1 from "../assets/logos/energia_sinfronteras.jpg"; // Asegúrate de tener estas imágenes en la ruta correcta
import brand2 from "../assets/logos/logo_zimpertec.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Brands = () => {
  const brands = [
    {
      name: "Energia Sin Fronteras",
      image: brand1,
      url: "https://www.energiasinfronteras.org",
    },
    { name: "Zimpertec", image: brand2, url: "https://www.zimpertec.com" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="p-6 md:p-10 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4 md:mb-6">
            Nuestras Marcas Asociadas
          </h2>
        </div>
        <Slider {...settings}>
          {brands.map((brand, index) => (
            <div key={index} className="flex justify-center p-4">
              <a href={brand.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={brand.image}
                  alt={brand.name}
                  className="w-full h-48 object-contain transition-transform duration-300 hover:scale-105"
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Brands;
