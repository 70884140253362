import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import LogoLucesNuevas from "../assets/logos/logo_lucesnuevas.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [scroll, setScroll] = useState(false);
  const location = useLocation();

  const handleClick = () => setNav(!nav);

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full h-[80px] fixed top-0 z-50 transition-all duration-300 ${
        scroll ? "bg-navbarBg shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="flex justify-between items-center w-full h-full px-4">
        <div className="text-primary text-2xl font-bold flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={LogoLucesNuevas}
              alt="Luces Nuevas"
              className="h-10 mr-2 md:h-12 md:mr-4"
            />
          </Link>
        </div>
        <ul className="hidden md:flex space-x-6 font-semibold">
          <li className="group">
            <Link
              to="/"
              className={`text-white px-4 py-2 relative ${
                isActive("/") ? "text-primary" : ""
              }`}
            >
              Inicio
              <span
                className={`absolute left-0 bottom-0 w-full h-0.5 bg-primary transform ${
                  isActive("/") ? "scale-x-100" : "scale-x-0"
                } group-hover:scale-x-100 transition-transform duration-300 ease-in-out`}
              ></span>
            </Link>
          </li>
          <li className="group">
            <Link
              to="/about"
              className={`text-white px-4 py-2 relative ${
                isActive("/about") ? "text-primary" : ""
              }`}
            >
              Acerca de
              <span
                className={`absolute left-0 bottom-0 w-full h-0.5 bg-primary transform ${
                  isActive("/about") ? "scale-x-100" : "scale-x-0"
                } group-hover:scale-x-100 transition-transform duration-300 ease-in-out`}
              ></span>
            </Link>
          </li>
          <li className="group">
            <Link
              to="/projects"
              className={`text-white px-4 py-2 relative ${
                isActive("/projects") ? "text-primary" : ""
              }`}
            >
              Proyectos
              <span
                className={`absolute left-0 bottom-0 w-full h-0.5 bg-primary transform ${
                  isActive("/projects") ? "scale-x-100" : "scale-x-0"
                } group-hover:scale-x-100 transition-transform duration-300 ease-in-out`}
              ></span>
            </Link>
          </li>
          <li className="group">
            <Link
              to="/contact"
              className={`text-white px-4 py-2 relative ${
                isActive("/contact") ? "text-primary" : ""
              }`}
            >
              Contacto
              <span
                className={`absolute left-0 bottom-0 w-full h-0.5 bg-primary transform ${
                  isActive("/contact") ? "scale-x-100" : "scale-x-0"
                } group-hover:scale-x-100 transition-transform duration-300 ease-in-out`}
              ></span>
            </Link>
          </li>
        </ul>
        <div className="md:hidden z-10" onClick={handleClick}>
          {!nav ? (
            <FontAwesomeIcon icon={faBars} className="w-8 text-white" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="w-8 text-white" />
          )}
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-navbarBg bg-opacity-100 flex flex-col justify-center items-center transition-transform duration-300 ease-in-out ${
          nav ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="absolute top-4 left-4 flex items-center">
          <Link to="/" className="flex items-center" onClick={handleClick}>
            <img
              src={LogoLucesNuevas}
              alt="Luces Nuevas"
              className="h-12 mr-2 md:h-12 md:mr-4"
            />
          </Link>
        </div>
        <ul className="flex flex-col items-center space-y-6 font-semibold">
          <li className="text-4xl text-white hover:text-primary transition duration-300">
            <Link to="/" onClick={handleClick}>
              Inicio
            </Link>
          </li>
          <li className="text-4xl text-white hover:text-primary transition duration-300">
            <Link to="/about" onClick={handleClick}>
              Acerca de
            </Link>
          </li>
          <li className="text-4xl text-white hover:text-primary transition duration-300">
            <Link to="/projects" onClick={handleClick}>
              Proyectos
            </Link>
          </li>
          <li className="text-4xl text-white hover:text-primary transition duration-300">
            <Link to="/contact" onClick={handleClick}>
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
