import React from "react";

const Mission = () => {
  return (
    <div className="p-6 md:p-10 bg-gray-100">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4 md:mb-6">
            Nuestra Misión y Objetivos
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="flex flex-col space-y-6">
            <img
              src=""
              alt="Objetivos"
              className="w-full h-auto object-cover rounded-lg shadow-lg"
            />
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center">
              <h3 className="text-2xl font-bold text-primary mb-4">
                Nuestra Misión
              </h3>
              <p className="text-base md:text-lg text-gray-700">
                Luces Nuevas Internacional tiene como misión proporcionar acceso
                universal y sostenible a los servicios básicos y a la seguridad
                alimentaria a través de soluciones innovadoras de energía solar.
              </p>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center">
            <h3 className="text-2xl font-bold text-primary mb-4">Objetivos</h3>
            <p className="text-base md:text-lg text-gray-700">
              En el marco de los Objetivos de Desarrollo Sostenible, Luces
              Nuevas Internacional ha diseñado un modelo de diagnóstico y
              gestión para guiar en la planificación y ejecución de las
              Soluciones Tecnológicas de Acceso Universal a la Energía. De forma
              complementaria proponemos acciones en el ámbito de Acceso
              Universal a las Tecnologías de la Información y Comunicación; así
              como acciones de Seguridad Alimentaria con Agricultura y Manejo
              Forestal Sostenible.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
