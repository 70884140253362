import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import project1 from "../assets/images/planificacion_dinamica2.png"; // Asegúrate de tener esta imagen en la ruta correcta
import project2 from "../assets/images/pasorapa_entrega.jpeg";
import project3 from "../assets/images/consecuencia.png";
import project4 from "../assets/images/solucion.png";

const Projects = () => {
  const projects = [
    {
      title: "Gobernanza y Planificación Dinámica",
      description:
        "Implementamos procesos de gobernanza que incluyen la participación del estado, la sociedad civil y las instituciones privadas para generar diálogos y consensos en la toma de decisiones.",
      image: project1,
    },
    {
      title: "Proyecto en Pasorapa",
      description:
        "En 2014, Pasorapa se convirtió en el primer municipio en Bolivia con acceso universal a la energía mediante picolámparas, beneficiando a 380 familias.",
      image: project2,
    },
    {
      title: "Proyecto en Tiquipaya",
      description:
        "Tiquipaya ha implementado modelos de gestión para el acceso universal a la energía, integrando a todas las partes interesadas y promoviendo un ambiente de cooperación.",
      image: project3,
    },
    {
      title: "Soluciones Tecnológicas",
      description:
        "Utilizamos tecnologías eficientes y sostenibles como litio solar AC y DC, microgrids y cocinas de pellets para proporcionar soluciones energéticas a las comunidades.",
      image: project4,
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header
        className="relative w-full h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${project2})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <h1 className="text-3xl md:text-5xl font-bold text-white text-center">
            Nuestros Proyectos
          </h1>
        </div>
      </header>
      <main className="flex-grow p-6 md:p-10">
        <div className="max-w-7xl mx-auto">
          {projects.map((project, index) => (
            <section key={index} className="mb-10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-auto object-cover rounded-lg shadow-lg"
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">
                    {project.title}
                  </h2>
                  <p className="text-base md:text-lg text-gray-700">
                    {project.description}
                  </p>
                </div>
              </div>
            </section>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Projects;
