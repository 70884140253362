import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header
        className="relative w-full h-64 bg-cover bg-center"
        style={{
          backgroundImage: `url('https://via.placeholder.com/1920x500')`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <h1 className="text-3xl md:text-5xl font-bold text-white text-center">
            Contáctanos
          </h1>
        </div>
      </header>
      <main className="flex-grow p-6 md:p-10">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-6 text-center">
            Ponte en Contacto
          </h2>
          <form className="space-y-6 mb-6">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Nombre
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-primary"
                placeholder="Tu nombre"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Correo Electrónico
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-primary"
                placeholder="Tu correo electrónico"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                Mensaje
              </label>
              <textarea
                id="message"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-primary"
                rows="5"
                placeholder="Tu mensaje"
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="px-4 py-2 bg-primary text-white font-bold rounded-lg hover:bg-primary-dark focus:outline-none focus:ring"
              >
                Enviar
              </button>
            </div>
          </form>
          <div className="text-center text-lg text-gray-700">
            <p>
              Envía un correo a{" "}
              <a
                href="mailto:lucesnuevas@lucesnuevas.org"
                className="text-primary font-bold"
              >
                lucesnuevas@lucesnuevas.org
              </a>{" "}
              para obtener más información sobre nosotros.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;
