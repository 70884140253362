import React, { useState } from "react";
import problema from "../assets/images/problema.png";
import consecuencia from "../assets/images/consecuencia.png";
import solucion from "../assets/images/solucion.png";
import planificacion from "../assets/images/planificacion.png";
import planificacion_dinamica from "../assets/images/planificacion_dinamica.png";
import ejecucion from "../assets/images/ejecucion.png";

const InformationSection = () => {
  const [expanded, setExpanded] = useState(Array(6).fill(false));

  const handleToggle = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const information = [
    {
      title: "El problema",
      description:
        "La falta de acceso a la energía es un problema crucial que afecta a millones de personas en todo el mundo. Sin energía, las comunidades enfrentan desafíos significativos en su vida diaria.",
      image: problema,
      details:
        "La falta de energía impide el desarrollo económico, afecta la educación y la salud de las personas. Las comunidades rurales son las más afectadas por la falta de acceso a fuentes de energía sostenibles y asequibles.",
    },
    {
      title: "Consecuencias",
      description:
        "La ausencia de energía tiene graves consecuencias para la salud, la economía y la educación. La falta de iluminación adecuada, por ejemplo, puede dificultar el estudio de los niños y aumentar los riesgos de accidentes domésticos.",
      image: consecuencia,
      details:
        "Las consecuencias de la falta de energía son extensas y variadas, afectando todos los aspectos de la vida cotidiana. Desde la salud y la seguridad hasta las oportunidades educativas y económicas, la falta de energía es una barrera significativa para el desarrollo.",
    },
    {
      title: "Una solución para todos",
      description:
        "Implementamos un diagrama de acceso universal a la energía que proporciona soluciones sostenibles y asequibles, asegurando que todas las personas puedan beneficiarse de la energía renovable.",
      image: solucion,
      details:
        "Nuestro enfoque se basa en soluciones renovables y sostenibles, diseñadas para ser asequibles y accesibles para todos. Trabajamos en colaboración con comunidades locales para implementar soluciones que se adapten a sus necesidades específicas.",
    },
    {
      title: "Planificación",
      description:
        "Desarrollamos un modelo de gestión y una metodología detallada para planificar e implementar soluciones tecnológicas que permitan el acceso universal a la energía.",
      image: planificacion,
      details:
        "Nuestro modelo de gestión incluye la planificación estratégica, la identificación de recursos y la implementación de tecnologías avanzadas para garantizar un acceso universal a la energía sostenible.",
    },
    {
      title: "Planificación dinámica participativa",
      description:
        "Fomentamos la coordinación interinstitucional y la participación activa de las comunidades para garantizar que nuestras soluciones sean efectivas y sostenibles a largo plazo.",
      image: planificacion_dinamica,
      details:
        "La planificación dinámica participativa es esencial para el éxito de nuestras iniciativas. Involucramos a todas las partes interesadas, incluidas las comunidades locales, las organizaciones gubernamentales y no gubernamentales, en el proceso de planificación e implementación.",
    },
    {
      title: "Proceso de Ejecución",
      description:
        "Apoyamos el desarrollo de capacidades, la gobernanza y la implementación de políticas de estado sostenibles para asegurar que las soluciones sean duraderas y beneficien a las futuras generaciones.",
      image: ejecucion,
      details:
        "El proceso de ejecución incluye el desarrollo de capacidades, el fortalecimiento de la gobernanza y la implementación de políticas de estado que promuevan la sostenibilidad a largo plazo. Trabajamos para asegurar que nuestras soluciones no solo sean efectivas, sino también duraderas.",
    },
  ];

  return (
    <div className="p-6 md:p-10 bg-gray-100">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4 md:mb-6">
            Nuestro Enfoque y Soluciones
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {information.map((info, index) => (
            <div
              key={index}
              className="flex flex-col bg-white p-6 rounded-lg shadow-lg h-full"
            >
              <div className="w-full h-48 overflow-hidden rounded-lg mb-4">
                <img
                  src={info.image}
                  alt={info.title}
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-2xl font-bold text-primary mb-2">
                {info.title}
              </h3>
              <p className="text-base md:text-lg text-gray-700 flex-grow">
                {info.description}
              </p>
              {expanded[index] && (
                <p className="text-base md:text-lg text-gray-700 mt-2">
                  {info.details}
                </p>
              )}
              <button
                onClick={() => handleToggle(index)}
                className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition duration-300"
              >
                {expanded[index] ? "Ver menos" : "Ver más"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
