import React from "react";
import BannerImage from "../assets/images/header_1.jpg";

const Header = () => {
  return (
    <div
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${BannerImage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-70 w-full h-full"></div>
      <div className="relative flex flex-col justify-center items-center h-full text-center z-10 px-4">
        <h1 className="text-white text-4xl md:text-5xl font-bold mb-4">
          LUCES NUEVAS INTERNACIONAL
        </h1>
        <h2 className="text-white text-xl md:text-2xl mb-8">
          Acceso Universal Sostenible a los Servicios Básicos y Seguridad
          Alimentaria
        </h2>
      </div>
    </div>
  );
};

export default Header;
