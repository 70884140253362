import React from "react";
import Header from "../components/Header";
import Mission from "../components/Mission";
import InformationSection from "../components/InformationSection";
import Brands from "../components/Brands";

const Home = () => {
  return (
    <div className="relative overflow-x-hidden">
      <Header />
      <Mission />
      <InformationSection />
      <Brands />
    </div>
  );
};

export default Home;
